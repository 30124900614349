<template>
  <v-card flat>
    <v-card-text>
    <date-range-editor v-model="rangeSelected"> </date-range-editor>
    </v-card-text>
    <v-card-actions>
    <v-btn @click="changeManual1">Zmień 1</v-btn>
    <v-btn @click="changeManual2">Zmień 2</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  data: () => ({
    rangeSelected: null
  }),
  watch: {
    rangeSelected(value) {
      console.log("range has been changed :>> ", value.id, value)
      if (value?.range) {
        var query = { from: value.range.from, to: value.range.to }
        if (JSON.stringify(query) != JSON.stringify(this.$route.query)) {
          this.$router.replace({
            path: this.$route.path,
            query: query,
            hash: this.$route.hash
          })
        }
      } else {
        console.log("else -----", value)
      }
    }
  },
  methods: {
    changeManual1() {
      this.rangeSelected = {
        id: "custom",
        from: "2020-12-10",
        to: "2020-12-12"
      }
    },
    changeManual2() {
      this.rangeSelected = {
        id: "custom",
        from: "2020-12-01",
        to: "2020-12-04"
      }
    }
  },
  beforeMount() {
    console.log("query before", this.selectedTab)
    const query = this.$route.query
    if (query.from) {
      this.rangeSelected = {
        id: "custom",
        from: query.from,
        to: query.to
      }
    }
  }
}
</script>